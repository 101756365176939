import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AppLoginComponent} from './components/app-login/app-login.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AuthCustomerGuard} from './guards/auth-customer.guard';
import {AuthConsultantGuard} from './guards/auth-consultant.guard';
const routes: Routes = [
  {
    //Route for videochat
    path: ':clientSlug/video-chat',
    loadChildren: () => import('./pages/video-chat-extended/video-chat-extended.module').then( m => m.VideoChatExtendedPageModule)
  },
  {
    //Route for videochat
    path: ':clientSlug/video-chat/:hash',
    loadChildren: () => import('./pages/video-chat-extended/video-chat-extended.module').then( m => m.VideoChatExtendedPageModule)
  },
  {
    //Route for immediate login over SmartApp
    path: ':clientSlug/login',
    component: AppLoginComponent,
  },
  {
    //Route for welcome page
    path: ':clientSlug/welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    //Route for consultants overview
    path: ':clientSlug/consultants',
    loadChildren: () => import('./pages/overview/consultants/consultants.module').then( m => m.ConsultantsPageModule),
  },
  {
    //Route for appointment overview page of given consultant
    path: ':clientSlug/consultants/:consultantId/appointments',
    loadChildren: () => import('./pages/overview/appointments/appointments.module').then( m => m.AppointmentsPageModule),
  },
  {
    //Route for appointment booking page
    path: ':clientSlug/consultants/:consultantId/appointments/booking',
    loadChildren: () => import('./pages/appointment-booking/appointment-booking.module').then( m => m.AppointmentBookingPageModule)
  },
  {
    //Route for error
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    //Catch all route
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
