export const APP = {
  locale: 'de',
  dateLocale: 'de-DE',
  homePage: 'video-chat',
  api: {
    //baseUrl: 'http://0.0.0.0/api',
    baseUrl: 'https://smartkis-staging.hutter-unger.de/api',
    slug: '/v5'
  },
  // userType:'consultant'
  userType:'customer'
};
