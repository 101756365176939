import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Capacitor} from '@capacitor/core';
import {PermissionStatus, PushNotifications} from '@capacitor/push-notifications';
import {App} from '@capacitor/app';
import {APP} from '../../configuration';
import {StorageService} from '../basic/storage.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private enabled = false;
  private oneSignalIosApiKey: string = 'ab0102af-4bd9-4e95-9ab9-950e0c4f03c1';
  private oneSignalAndroidApiKey = '';

  constructor(private http: HttpClient,
              private oneSignal: OneSignal,
              private platform: Platform,
              private storageService: StorageService
  ) {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appStateChange', ({isActive}) => {
        if (isActive) {
          this.checkPushPermissions();
        }
      });
    }
  }

  isEnabled() {
    return this.enabled;
  }

  // openSystemSettings() {
  //     if (this.platform.is('cordova')) {
  //         this.openNativeSettings.open('application_details').then(res => {
  //             LogService.info('[PushService] Notification settings openend');
  //         });
  //     }
  // }

  initialize() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    this.checkPushPermissions();
    console.log('PushProvider: Initialize OneSignal');
    this.oneSignal.startInit(this.oneSignalIosApiKey);
    this.oneSignal.iOSSettings({
      kOSSettingsKeyAutoPrompt: false,
      kOSSettingsKeyInAppLaunchURL: false
    });
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Check for deep links and route correctly
      console.log('[PushService] Openend notification with payload');

      if (data.notification.payload.additionalData.deeplink) {
        console.log('[PushService] Notification Payload', [data.notification.payload.additionalData]);
        // this.linkReceivedEvent.next(data.notification.payload.additionalData.url);
        // this.events.publish('deeplink:received', match);
      }
    });
    // this.oneSignal.setLogLevel({logLevel: 5, visualLevel: 5});
    this.oneSignal.endInit();
  }


  askForPushNotifications() {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      console.log('PushProvider: Ask for permission');
      this.oneSignal.promptForPushNotificationsWithUserResponse().then(res => {
        console.log('PushProvider: Permission to receive notifications was ' + res);
        this.enabled = res;
        this.postPlayerId();
      }, error => {
        setTimeout(() => {
          this.postPlayerId();
        }, 10000);
      });

    }
  }

  // deletePlayerId() {
  //     if (this.platform.is('cordova')) {
  //         console.log('PushProvider: deleting player id');
  //
  //         this.oneSignal.getIds().then(res => {
  //             let data = {
  //                 player_id: res.userId,
  //                 app_id: LocalConfigService.getAppId(),
  //                 api_version: LocalConfigService.getApiVersion()
  //             };
  //             this.http.post(LocalConfigService.getDeletePlayerIdUrl(), data, httpOptions)
  //                 .subscribe((res: any) => {
  //                     console.log('PushProvider: Deleting Player ID success: ' + JSON.stringify(res));
  //                 }, error => {
  //                     console.log('PushProvider: Deleting Player ID error: ' + JSON.stringify(error));
  //                 });
  //         }, error => {
  //             console.log('PushProvider: Error retrieving player id ' + error);
  //         });
  //     }
  // }

  postPlayerId() {
    this.oneSignal.getIds().then(async result => {
      console.log('[PushService] OneSignal IDs', [result]);

      const data = {
        player_id: result.userId,
      };

      const clientId = await this.storageService.get('clientId');
      const userId = await this.storageService.get('userId');
      const apiToken = await this.storageService.get('apiToken');

      this.http.post(APP.api.baseUrl + '/v5/clients/' + clientId + '/users/' + userId + '/players/create?api_token=' + apiToken, data, httpOptions)
        .subscribe((response: any) => {
          console.log('PushService: Posting Player ID success: ' + JSON.stringify(response));
        }, error => {
          console.log('PushService: Posting Player ID error: ' + JSON.stringify(error));
        });
    });
  }

  private checkPushPermissions() {
    PushNotifications.checkPermissions().then((state: PermissionStatus) => {
      if (status === 'granted') {
        this.enabled = true;
      } else {
        this.enabled = false;
      }
      console.log('PushProvider: Checking push status', this.enabled);
    });
  }
}
