import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../services/basic/storage.service';

@Component({
  selector: 'app-app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.scss'],
})
export class AppLoginComponent implements OnInit {

  private jwtHelper: JwtHelperService;

  constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService) {
    this.jwtHelper = new JwtHelperService();
  }

  ngOnInit() {
    const clientSlug = this.route.snapshot.paramMap.get('clientSlug');
    this.route.queryParams.subscribe(async (params) => {
      //check if url contains token
      if (params && params.token) {
        const token = params.token;
        const tokenPayload = this.jwtHelper.decodeToken(token);
        await this.storageService.set('token', token);
        await this.storageService.set('customer', tokenPayload.customer);
        await this.router.navigate([clientSlug + '/consultants']);
      } else {
        //check if token already exists
        try {
          const token = await this.storageService.get('token');
          if (token !== null && !this.jwtHelper.isTokenExpired(token)) {
            await this.router.navigate([clientSlug + '/consultants']);
          } else {
            await this.router.navigate([clientSlug + '/login/customer']);
          }
        } catch (e) {
          //if invalid jwt exists in storage
          await this.router.navigate([clientSlug + '/login/customer']);
        }
      }
    });
  }

}
