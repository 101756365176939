import { Component, OnInit } from '@angular/core';
import {StorageService} from '../../services/basic/storage.service';
import {ClientOptions} from '../../models/ClientOptions';
import * as moment from 'moment';
import {ClientService} from '../../services/client.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public imprintUrl: string;
  public dataSecurityUrl: string;
  public logoUrl: string;
  public clientName: string;
  public currentYear: number;

  constructor(private storageService: StorageService, private clientService: ClientService) { }

  ngOnInit() {
    this.clientService.clientInfo.subscribe(async clientOptions => {
      if (clientOptions) {
        this.imprintUrl = clientOptions.imprint_url;
        this.dataSecurityUrl = clientOptions.data_security_url;
        this.logoUrl = clientOptions.logo;
        this.clientName = clientOptions.client_name;
        this.currentYear = moment().year();
      }
    });
  }

}
