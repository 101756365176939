import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {StorageService} from './services/basic/storage.service';
import {ClientService} from './services/client.service';
import {catchError} from 'rxjs/operators';
import {ClientResponse} from './models/ClientResponse';
import {PushService} from './services/feature/push.service';
import {ClientOptions} from './models/ClientOptions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {title: 'Video-Chat', url: '/video-chat', icon: 'mail'}
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    public translate: TranslateService,
    private router: Router,
    private platform: Platform,
    private storageService: StorageService,
    private clientService: ClientService,
    private pushService: PushService,
  ) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('de');

    this.platform.ready().then(() => {
      this.pushService.initialize();
    });

    // Get client slug and load client data from API
    this.router.events.subscribe(async (result) => {

      if (result instanceof NavigationEnd) {
        const urlSegments = result.url.split('/');
        const clientSlug = urlSegments[1];

        // const clientSlug = 'fashionsports';

        // If client slug is "login", show the consultant login page
        if (clientSlug === 'null' || clientSlug === 'login' || clientSlug === '') {
          return;
        }

        await this.storageService.set('clientSlug', clientSlug);

        this.clientService.getClient(clientSlug).subscribe(async (clientResponse: ClientOptions) => {
          this.clientService.setClient(clientResponse);
        }, error => {
          this.router.navigateByUrl('error');
          console.log(error);
        });
      }
    });
  }
}
