import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from "./basic/storage.service";

@Injectable({
  providedIn: 'root'
})

export class AvailabilityService {

  private isAvailable = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  public setAvailability(value: boolean){
    this.isAvailable.next(value);
  }

  public getAvailability(){
    return this.isAvailable.asObservable();
  }
}
