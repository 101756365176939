import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {APP} from '../configuration';
import {StorageService} from './basic/storage.service';
import {ClientOptions} from '../models/ClientOptions';
import has = Reflect.has;
import {BehaviorSubject} from 'rxjs';
import {ClientResponse} from '../models/ClientResponse';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private endpoint = APP.api.baseUrl + '/v5/clients';
  private clientInfo$ = new BehaviorSubject<ClientOptions>(null);
  public clientInfo = this.clientInfo$.asObservable();

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
  }

  getClient(clientSlug) {
    return this.http.get(this.endpoint + '/' + clientSlug);
  }

  async hasDirectCall() {
    return this.clientInfo$.value.has_direct_call;
  }

  async hasVideoAppointments() {
    return this.clientInfo$.value.has_video_appointments;
  }

  async hasStoreAppointments() {
    return this.clientInfo$.value.has_store_appointments;
  }

  setClient(clientResponse: ClientOptions) {
    console.log("setting client response", clientResponse);
    this.clientInfo$.next(clientResponse);
  }

  getClientId(): number {
    return this.clientInfo$.value.client_id;
  }
}
