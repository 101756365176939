import {Component, Input, OnInit} from '@angular/core';
import {AppointmentStoreResponse} from '../../models/AppointmentStoreResponse';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {StorageService} from '../../services/basic/storage.service';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
})
export class BookingConfirmationComponent implements OnInit {

  moment: any = moment;

  @Input() appointment: AppointmentStoreResponse;

  constructor(private storageService: StorageService, private router: Router, private modalController: ModalController) {
  }

  ngOnInit() {
  }

   async nagivateToOverview() {
    this.modalController.getTop().then( modal  => {
      modal.dismiss();
    });

    const clientSlug = await this.storageService.get('clientSlug');
    await this.router.navigateByUrl(clientSlug + '/consultants');
  }
}
