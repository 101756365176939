import {Injectable, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IonicStorageModule} from '@ionic/storage';
import {LocatorService} from './services/locator.service';
import {AvailabilityService} from './services/availability.service';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {BookingConfirmationComponent} from './components/booking-confirmation/booking-confirmation.component';
import {LayoutModule} from './modules/layout/layout.module';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Diagnostic} from '@ionic-native/diagnostic/ngx';

registerLocaleData(localeDe);

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);
    }
}

@NgModule({
    declarations: [AppComponent, BookingConfirmationComponent],
    entryComponents: [],
    imports: [
        CommonModule,
        LayoutModule,
        HttpClientModule,
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot({
            name: '__smartvideoapp',
            driverOrder: ['sqlite', 'indexeddb', 'websql']
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})],
    exports: [
        DatePipe
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        AvailabilityService,
        {provide: LOCALE_ID, useValue: 'de-DE'},
        DatePipe,
        OneSignal,
        Diagnostic,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    constructor(private injector: Injector) {
        LocatorService.injector = this.injector;
    }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

