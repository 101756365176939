import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/basic/storage.service';
import {ClientOptions} from '../../models/ClientOptions';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../services/client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public clientName: string;
  public logoUrl: string;
  public user = null;

  constructor(private storageService: StorageService, private router: Router, private activatedRoute: ActivatedRoute, private clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.clientService.clientInfo.subscribe(async clientOptions => {
      if (clientOptions) {
          this.clientName = clientOptions.client_name;
          this.logoUrl = clientOptions.logo;
          this.user = await this.storageService.get('user');
      }
    });
  }

  async logout() {
    this.user = null;
    await this.storageService.remove('user');
    await this.storageService.remove('token');
    await this.router.navigateByUrl('/');
  }

  async home() {
    const clientSlug = this.activatedRoute.snapshot.paramMap.get('clientSlug');
    await this.router.navigate([clientSlug + '/welcome']);
  }
}
